/**
 * Nosy Dsicipline Index
 *
 * @export DsiciplineIndex
 */
/**
 * Nosy Dsicipline
 *
 * @export Dsicipline
 */
import {
    AspectRatio,
    Center,
    chakra,
    Container, Flex, Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    useBreakpointValue
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import ForwardArrow from '../../components/foward-arrow/forward-arrow'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import SEO from '../../components/seo/seo'
import { useSiteSettings } from '../../hooks/use-site-settings'
import { mapEdgesToNodes } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_DISCIPLINE_INDEX_QUERY($ids: [String]) {
        allSanityDiscipline(
            filter: { id: { in: $ids } }
            sort: { fields: slug___current, order: ASC }
        ) {
            edges {
                node {
                    id
                    title
                    slug {
                        current
                    }
                    mainImage {
					    ...ImageWithPreview
                    }
                }
            }
        }
        allSanityChildDisciplines: allSanityDiscipline(
            filter: { parentDiscipline: { id: { ne: null } } }
            sort: { fields: title, order: ASC }
        ) {
            edges {
                node {
                    id
                    title
                    _rawParentDiscipline(resolveReferences: { maxDepth: 10 })
                }
            }
        }
    }
`

const DisciplineIndex = ( props ) => {
    const {
        data: { allSanityDiscipline = [], allSanityChildDisciplines = [] }
    } = props

    // Map edges to node items
    const disciplines = mapEdgesToNodes( allSanityDiscipline )
    const childDisciplines = mapEdgesToNodes( allSanityChildDisciplines )

    const {
        disciplineIndexVideo = {},
    } = useSiteSettings()

    const ChakraReactPlayer = chakra( ReactPlayer, {
        shouldForwardProp: ( prop ) => prop
    } )

    // Manage loading state
    const [loading, setLoading] = useState( true )

    // Set image sizes for different viewport sizes
    const imageWidth = useBreakpointValue( {
        base: 540,  // 0-48em
        md: 920,    // 48em-80em,
        xl: 1080,   // 80em+
    } )

    return (
        <Layout>
            <SEO
                title='Disciplines'
                description='We create experiences which connect with your customers & colleagues.' />
            <Container maxWidth='6xl' centerContent>
                <Center flexDirection='column' maxWidth='3xl'>
                    <Heading
                        as='h1'
                        maxWidth='5xl'
                        fontSize={['5xl', '4xl', '6xl', '7xl']}
                        color='gray.100'
                        textAlign='center'
                        mt={12}
                        mb={24}
                        fontWeight='extrabold'>
                        We create ethical marketing solutions.
                    </Heading>
                </Center>
            </Container>

            {disciplineIndexVideo.url && (
                <Container maxWidth='8xl'>
                    <AspectRatio ratio={16 / 9} my={20}>
                        <Skeleton
                            isLoaded={!loading}
                            startColor='brand_primary.700'
                            endColor='brand_grayscale.darker'
                            width='full'
                            height='790px'
                            speed={1}>
                            <ChakraReactPlayer
                                onReady={() => setLoading( false )}
                                url={disciplineIndexVideo.url}
                                position='relative'
                                _after={{
                                    content: '""',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    position: 'absolute',
                                    width: 'full',
                                    height: 'full',
                                    background:
                                    'linear-gradient(90deg, rgba(0,3,9,1) 0%, rgba(0,0,0,0) 20%, rgba(15,15,15,0) 80%, rgba(0,3,9,1) 100%)'
                                }}
                                width='100%'
                                height='100%'
                                playing={process.env.NODE_ENV === 'production'}
                                muted
                                controls={false}
                                config={{
                                    youtube: {
                                        playerVars: {
                                            autoplay:
                                                process.env.NODE_ENV ===
                                                'production'
                                                    ? 1
                                                    : 0,
                                            modestbranding: 1,
                                            playsinline: 1,
                                            loop: 1
                                        }
                                    },
                                    vimeo: {
                                        playerOptions: {
                                            autoplay:
                                                process.env.NODE_ENV ===
                                                'production'
                                                    ? 1
                                                    : 0,
                                            byline: false,
                                            playsinline: true,
                                            loop: true
                                        }
                                    }
                                }} />
                        </Skeleton>
                    </AspectRatio>
                </Container>
            )}

            <Container maxWidth='6xl' py={36} centerContent>
                <Stack direction='column' spacing={48} maxWidth='2xl'>
                    <Stack direction='column' spacing={10} maxWidth='2xl'>
                        <Text
                            color='gray.100'
                            fontSize={['xl', '2xl' ]}
                            lineHeight='tall'
                            fontFamily='Modelica-Medium'>
                            Creativity is powerful — it influences both ourselves and the people around us. We've adopted an ethical stance to how we approach problems. Starting every project with proper conversation. We're continuously looking for new and better ways to execute creative projects that have a positive and long-term impact.
                        </Text>
                        {/* 
                            <Text
                                color='gray.100'
                                fontSize={['xl', '2xl' ]}
                                lineHeight='tall'
                                fontFamily='Modelica-Medium'>
                                We market in a planet-positive way by using ethical resources, minimising the materials we use, ensuring that what we create can serve multiple purposes and be re-used — and if it can't, making sure we offset the impact our actions have caused via re-foresting initiatives.
                            </Text> 
                        */}
                        <Text
                            color='gray.100'
                            fontSize={['xl', '2xl' ]}
                            lineHeight='tall'
                            fontFamily='Modelica-Medium'>
                            We are curious, creative, and forward-thinking - Solving problems, considering planet and people, and delivering honest messages that generate results.
                        </Text>
                    </Stack>
                    <Heading 
                        as='h4' 
                        fontSize='5xl' 
                        color='gray.100'
                        textAlign='center'>
                        We're here to help you share your story.
                    </Heading>
                </Stack>
            </Container>

            <Container maxWidth='full'>
                <Flex
                    height='max'
                    overflow='hidden'
                    position='relative'
                    _after={{
                        content: '""',
                        position: 'absolute',
                        width: 'full',
                        height: 'full',
                        background: 'linear-gradient(90deg, rgba(0,3,9,1) 0%, rgba(0,0,0,0) 20%, rgba(15,15,15,0) 80%, rgba(0,3,9,1) 100%)'
                    }}>
                    <SimpleGrid my={20} columns={[1, 2, 3]} spacing={[4, 6]}>
                        {disciplines.map( ( { node: discipline }, index ) => {
                            const {
                                id = '',
                                mainImage = {}
                            } = discipline

                            return (
                                <Image  
                                    key={id} 
                                    imageData={mainImage} 
                                    width={imageWidth}
                                    height={430}
                                    objectFit='cover' />
                            )

                        } )}
                    </SimpleGrid>
                </Flex>
            </Container>

            <Container maxWidth='6xl'>
                <SimpleGrid my={20} columns={[1, 2, 3]} spacing={[4, 6, 8, 16]}>
                    {disciplines.map( ( { node: discipline } ) => {
                        const {
                            id: disciplineId,
                            title = '',
                            slug: { current = '' }
                        } = discipline

                        return (
                            <LinkBox 
                                key={disciplineId} 
                                role='group'>
                                <Stack
                                    direction='column'
                                    height='full'
                                    spacing={12}
                                    pb={10}>
                                    <Stack
                                        direction='row'
                                        justifyContent={[
                                            'flex-start',
                                            'space-between'
                                        ]}
                                        alignItems='center'>
                                        <Heading
                                            as='h3'
                                            color='gray.100'
                                            mr={[6, 0]}
                                            _groupHover={{
                                                color: 'brand_secondary.500'
                                            }}>
                                            <LinkOverlay
                                                as={Link}
                                                to={`/disciplines/${current}/`}>
                                                {title}
                                            </LinkOverlay>
                                        </Heading>
                                        <ForwardArrow
                                            color='brand_secondary.500'
                                            transition='all 0.343s'
                                            opacity={[1, 0]}
                                            width='32px'
                                            height='32px'
                                            _groupHover={{ opacity: 1 }} />
                                    </Stack>
                                    {childDisciplines && (
                                        <Stack direction='column' spacing={3}>
                                            {childDisciplines.map(
                                                ( { node: childDiscipline } ) => {
                                                    const {
                                                        id: childDisciplineId = '',
                                                        title = '',
                                                        _rawParentDiscipline: {
                                                            id: parentDisciplineId = ''
                                                        }
                                                    } = childDiscipline

                                                    // Bail early if not a child of the current parent discipline
                                                    if (
                                                        disciplineId !==
                                                        parentDisciplineId
                                                    ) {
                                                        return null
                                                    }

                                                    return (
                                                        <Text
                                                            key={childDisciplineId}
                                                            color='gray.100'>
                                                            {title}
                                                        </Text>
                                                    )
                                                }
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                            </LinkBox>
                        )
                    } )}
                </SimpleGrid>
            </Container>
        </Layout>
    )
}

export default DisciplineIndex
